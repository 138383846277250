body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiContainer-root {margin-top: 24px !important;}
.MuiToolbar-root .accountButton {
    position: absolute;
    right: 20px;
}

.MuiDrawer-root .MuiListItemButton-root:has(a) {
    padding:0;
}
.MuiDrawer-root a {
    color: #222;
    display: block;
    padding: 8px 16px;
    width: 100%;
}
.MuiDrawer-root a.active {
    background: #E2EDEA;
    font-weight: bold;
}
.MuiContainer-root h1 {
    font-size:1.8em;
    margin: 0.2em 0;
}
.filterWrapper {

}
.filterGroup {
    gap: 15px;
    margin-bottom: 20px;
}
.filterWrapper .MuiOutlinedInput-root {
    padding: 6px !important;
}
.filterWrapper .MuiInputLabel-root {
    font-size: 14px;
}
.filterWrapper .MuiOutlinedInput-input {
    font-size: 14px;
    padding: 4px 4px 4px 6px !important;
}
.filterWrapper .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
    transform: translate(14px, 11px) scale(1);
} .filterWrapper .MuiFormControl-root, .filterWrapper .MuiAutocomplete-root, .filterWrapper .MuiButtonBase-root {
    margin-left: 0 !important;
    text-transform: none;
} form .MuiFormControl-root {
    margin-bottom: 15px;
} form .MuiButton-contained {
    padding: 15px 16px;
} .dropzone {
    border: 1px solid #ccc;
} h4.css-5lbw0b-MuiTypography-root {
    font-size:1.5rem;
} .cancelButton {
    border: 0 !important;
    margin-top: 15px !important;
}